import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  DocumentData,
  deleteDoc,
  Timestamp,
  getDocs,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { database } from "../Firebase/databaseConfig";
import { sendEmailToPerson, sendMailV2 } from "../Mails/handleNotifications";
import { toast } from "sonner";

type popup = {
  text: string;
  state: string;
};

const auth = getAuth();

const exist = (array: any[], value: string) => {
  return array.some((obj: any) => obj.id === value && obj.role === "Manager");
};

export const isUserProofed = (
  members: any[],
  setIsProofed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      setIsProofed(members.some((obj: any) => obj.id === id && obj.completed));
    }
  });
};

export const finishProject = (
  id: string,
  status: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const docRef = doc(database, "projects", `${id}`);

  updateDoc(docRef, {
    status: status,
    finished: status === "finished" ? new Date() : null,
  })
    .then(() => {
      toast.success("Le statut du projet a bien été modifié.");
    })
    .finally(() => {
      setIsLoading(false);
    });
};

interface Creator {
  name: string;
  email: string;
  id: string;
  nickname: string;
  picture: string;
}

interface ProjectData {
  id: string;
  name: string;
  description: string;
  status?: string;
  organisationId: string;
  location: object;
  created: Date;
  members: any[];
  teams: any[];
  finished: Date;
  creator: Creator;
  end: any;
  start: any;
}

const getUserOrganisation = async (userId: string) => {
  try {
    const userQuery = query(
      collection(database, "users"),
      where("id", "==", userId)
    );
    const userSnapshot = await getDocs(userQuery);

    if (userSnapshot.empty) {
      toast.error("Utilisateur non trouvé.");
    }

    const userData = userSnapshot.docs[0].data();

    const orgQuery = query(
      collection(database, "organisations"),
      where("id", "==", userData.organisationId)
    );
    const orgSnapshot = await getDocs(orgQuery);

    if (orgSnapshot.empty) {
      toast.error("Organisation non trouvée");
    }

    return {
      organisation: orgSnapshot.docs[0].data(),
      user: userData,
    };
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
    throw error;
  }
};

const checkUserPermissions = (
  organisation: DocumentData,
  userId: string
): boolean => {
  return (
    organisation.creator === userId ||
    (organisation.members || []).some((member: any) => member.id === userId)
  );
};

export const createProject = async (
  name: string,
  description: string,
  location: object,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end: any,
  status?: string,
  start?: any
): Promise<void> => {
  setIsLoading(true);

  try {
    const user = auth.currentUser;
    if (!user) {
      toast.error("Utilisateur non connecté.");
      return;
    }

    const { organisation, user: userData } = await getUserOrganisation(
      user.uid
    );

    if (!checkUserPermissions(organisation, user.uid)) {
      toast.error("Vous n'avez pas les permissions nécessaires.");
    }

    const projectId = uuid();
    const projectData: ProjectData = {
      id: projectId,
      name,
      description,
      status,
      organisationId: organisation.id,
      location,
      created: new Date(),
      members: [],
      teams: [],
      finished: new Date(),
      creator: {
        name: userData.name ?? "",
        email: userData.email ?? "",
        id: userData.id ?? "",
        nickname: userData.nickname ?? "",
        picture: userData.picture ?? "",
      },
      end,
      start: start || new Date(),
    };

    const docRef = doc(database, "projects", projectId);
    await setDoc(docRef, projectData);

    toast.success("Le projet a été créé avec succès.");
    setOpen(false);
  } catch (error) {
    console.error("Erreur lors de la création du projet:", error);
    toast.error(
      error instanceof Error
        ? error.message
        : "Une erreur est survenue lors de la création du projet."
    );
  } finally {
    setIsLoading(false);
  }
};

export const saveProject = async (
  project: DocumentData,
  name: string,
  description: string,
  location: object,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any
): Promise<void> => {
  setIsLoading(true);

  try {
    if (!project?.id) {
      toast.error("ID du projet manquant");
    }

    const updateData = {
      name,
      description,
      location,
      end: end === undefined ? project.end : end,
      start: start === undefined ? project.start : start,
      updatedAt: new Date(), // Ajout d'un timestamp de mise à jour
    };

    const docRef = doc(database, "projects", project.id);
    await updateDoc(docRef, updateData);

    toast.success("Les modifications ont été enregistrées avec succès.");
    setOpen(false);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du projet:", error);
    toast.error(
      error instanceof Error
        ? error.message
        : "Une erreur est survenue lors de la mise à jour du projet."
    );
  } finally {
    setIsLoading(false);
  }
};

export const deleteProject = async (
  id: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  await deleteDoc(doc(database, "projects", `${id}`))
    .then(() => {
      // setPopup({
      //   state: "success",
      //   text: `Le projet a été supprimée.`,
      // });
      // setIsVisible(true);
      toast.success("Ce projet a bien été supprimé.");
    })
    .catch(() => {
      // setPopup({
      //   state: "error",
      //   text: `Un problème est survenu.`,
      // });
      // setIsVisible(true);
      toast.error(
        "Une erreur s'est produite lors de la suppression de ce projet."
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const renderProjects = (
  setProjects: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects"),
                where("organisationId", "==", `${organisation.id}`)
              );

              onSnapshot(q, async (querySnapshot) => {
                setProjects([]);
                querySnapshot.forEach((obj) => {
                  let project = obj.data();
                  let projectMembers = project.members;

                  if (id === creator || exist(members, id)) {
                    setProjects((old) => [...old, obj.data()]);
                  } else if (members.some((obj: member) => obj.id === id)) {
                    if (projectMembers.some((obj: any) => obj.id === id)) {
                      setProjects((old) => [...old, obj.data()]);
                    }
                  }
                });
                setIsLoading(false);
              });
              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getAllOrgMembers = (
  setOrgMembers: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            setOrgMembers([]);
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator || exist(members, id)) {
                setOrgMembers(organisation.members);
                setIsLoading(false);
              }
              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getAllTeams = (
  setTeams: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              if (id === creator || exist(members, id)) {
                const q = query(
                  collection(database, "teams"),
                  where("organisationId", "==", `${organisation.id}`)
                );

                onSnapshot(q, async (querySnapshot) => {
                  setTeams([]);
                  querySnapshot.forEach((obj) => {
                    setTeams((old) => [...old, obj.data()]);
                  });
                  setIsLoading(false);
                });
              }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

export const getProject = (
  projectId: string,
  setProject: React.Dispatch<React.SetStateAction<DocumentData>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects"),
                where("id", "==", `${projectId}`)
              );

              onSnapshot(q, async (querySnapshot) => {
                querySnapshot.forEach((obj) => {
                  let projectMembers = obj.data().members;

                  if (
                    id === creator ||
                    exist(members, id) ||
                    projectMembers.some((obj: any) => obj.id === id)
                  ) {
                    setProject(obj.data());
                  }
                });
                setIsLoading(false);
              });

              // else {
              //   alert("Vous n'avez pas accès à cette page.");
              //   setIsLoading(false);
              // }
            });
          });
        });
      });
    } else {
      setIsLoading(false);
    }
  });
};

const timestampToDate = (timestamp: Timestamp) => {
  const seconds = timestamp.seconds;
  const milliseconds = timestamp.nanoseconds / 1000000;
  return new Date(seconds * 1000 + milliseconds);
};

export const getProjectTasks = (
  projectId: string,
  setToDoTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setDoingTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setFinishedTasks: React.Dispatch<React.SetStateAction<DocumentData[]>>,
  setData: React.Dispatch<React.SetStateAction<number[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAllTasks?: React.Dispatch<any>
) => {
  setIsLoading(true);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      let id = user.uid;

      const q = query(
        collection(database, "users"),
        where("id", "==", `${id}`)
      );

      onSnapshot(q, async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let user = doc.data();

          const q = query(
            collection(database, "organisations"),
            where("id", "==", `${user.organisationId}`)
          );

          onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach((obj) => {
              let organisation = obj.data();

              let creator: string = organisation.creator;
              let members: member[] = organisation.members;

              const q = query(
                collection(database, "projects/" + projectId + "/tasks/")
              );
              onSnapshot(q, (querySnapshot) => {
                setToDoTasks([]);
                setDoingTasks([]);
                setFinishedTasks([]);
                setData([]);
                setAllTasks !== undefined && setAllTasks([]);
                let total = 0;
                let toDo = 0;
                let doing = 0;
                let finished = 0;
                querySnapshot.forEach((obj) => {
                  let task = obj.data();
                  let taskMembers = task.members;

                  if (task.duplicate !== null) {
                    const startDate = task.start.toDate();
                    const endDate = task.end.toDate();
                    const startHour = startDate.getHours();
                    const startMinute = startDate.getMinutes();
                    const startSecond = startDate.getSeconds();

                    // Créez une copie indépendante de startDate
                    const currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                      // Créez une copie indépendante de currentDate
                      const currentDateCopy = new Date(currentDate);

                      currentDateCopy.setHours(startHour);
                      currentDateCopy.setMinutes(startMinute);
                      currentDateCopy.setSeconds(startSecond);

                      const finalEndDate = new Date(
                        currentDateCopy.getFullYear(),
                        currentDateCopy.getMonth(),
                        currentDateCopy.getDate(),
                        endDate.getHours(),
                        endDate.getMinutes(),
                        endDate.getSeconds()
                      );

                      const data = {
                        id: task.id,
                        description: task.description,
                        location: task.location,
                        title: task.name,
                        start: currentDateCopy,
                        end: finalEndDate,
                        duplicate: task.duplicate,
                      };

                      setAllTasks !== undefined &&
                        setAllTasks((old: any) => [...old, data]);

                      currentDate.setDate(currentDate.getDate() + 1);
                    }
                  } else if (task.duplicate === null) {
                    const data = {
                      id: task.id,
                      description: task.description,
                      location: task.location,
                      title: task.name,
                      start: timestampToDate(task.start),
                      end: timestampToDate(task.end),
                      duplicate: task.duplicate,
                    };
                    setAllTasks !== undefined &&
                      setAllTasks((old: any) => [...old, data]);
                  }

                  total++;

                  if (task !== undefined) {
                    if (task.status === "toDo") {
                      if (id === creator || exist(members, id)) {
                        setToDoTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setToDoTasks((old: any) => [...old, task]);
                        }
                      }

                      toDo++;
                    }

                    if (task.status === "doing") {
                      if (id === creator || exist(members, id)) {
                        setDoingTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setDoingTasks((old: any) => [...old, task]);
                        }
                      }
                      doing++;
                    }

                    if (task.status === "finished") {
                      if (id === creator || exist(members, id)) {
                        setFinishedTasks((old: any) => [...old, task]);
                      } else if (members.some((obj: member) => obj.id === id)) {
                        if (taskMembers.some((obj: any) => obj.id === id)) {
                          setFinishedTasks((old: any) => [...old, task]);
                        }
                      }
                      finished++;
                    }
                  }
                });

                setData([total, toDo, doing, finished]);

                setIsLoading(false);
              });
            });
          });
        });
      });
    }
  });
};

export const deleteTask = async (
  project: DocumentData,
  taskId: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const collectionRef = collection(
    database,
    "projects/" + `${project.id}` + "/tasks/"
  );
  await deleteDoc(doc(collectionRef, taskId))
    .then(() => {
      // setPopup({
      //   state: "success",
      //   text: `La tache a été supprimée.`,
      // });
      // setIsVisible(true);
      toast.success("La tâche a bien été supprimée.");
    })
    .catch(() => {
      // setPopup({
      //   state: "error",
      //   text: `Un problème est survenu.`,
      // });
      // setIsVisible(true);
      toast.error("Un problème est survenu.");
    });
};

export const removeTeamFromTask = async (
  teamId: string,
  task: DocumentData,
  project: DocumentData,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // Demander confirmation avant de retirer l'équipe
  const result = window.confirm(
    `Voulez-vous retirer cette équipe ainsi que ses membres de la tâche "${task.name}"?`
  );

  if (!result) {
    return;
  }

  setIsLoading(true);

  try {
    // Référence vers la tâche dans Firestore
    const collectionRef = collection(database, `projects/${project.id}/tasks/`);
    const docRef = doc(collectionRef, task.id);

    // Mise à jour de la tâche en supprimant l'équipe spécifique
    await updateDoc(docRef, {
      teams: deleteObjById(teamId, task.teams),
    });

    // Requête pour récupérer les informations de l'équipe à supprimer
    const q = query(collection(database, "teams"), where("id", "==", teamId));
    const querySnapshot = await getDocs(q);

    // Pour chaque équipe récupérée (devrait être une seule équipe), retirer les membres
    querySnapshot.forEach((obj) => {
      const team = obj.data();
      const members = team.members || [];

      // Retirer chaque membre de la tâche
      members.forEach(async (member: any) => {
        await removeFromTask(member.id, task.id, project.id);
      });
    });

    // Notification de succès
    toast.success("L'équipe et ses membres ont bien été retirés de la tâche.");
  } catch (err) {
    console.error("Erreur lors du retrait de l'équipe:", err);
    toast.error("Un problème est survenu lors du retrait de l'équipe.");
  } finally {
    // Arrêter l'indicateur de chargement
    setIsLoading(false);
  }
};

export const modifyTask = async (
  taskId: string,
  project: DocumentData,
  name: string,
  description: string,
  location: string,
  status: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any,
  duplicate?: object | null,
  modify?: boolean
) => {
  setIsLoading(true);

  let data: any = {
    name: name,
    description: description,
    location: location,
    status: status,
    finished: status === "finished" ? new Date() : null,
    // duplicate: duplicate ? duplicate : null,
    ...(duplicate !== undefined &&
      duplicate !== null && {
        duplicate: duplicate,
      }),
    ...(start !== undefined &&
      modify !== true && {
        start: start,
      }),
    ...(end !== undefined && modify !== true && { end: end }),
  };

  console.log(data);

  const collectionRef = collection(
    database,
    "projects/" + project.id + "/tasks/"
  );
  const docRef = doc(collectionRef, taskId);

  updateDoc(docRef, data)
    .then(() => {
      // setPopup({
      //   state: "success",
      //   text: `Les modifications ont été enregistrées.`,
      // });

      toast.success("Les modifications ont bien été appliquées.");
    })
    .catch((err) => {
      console.error(err);
      // setPopup({
      //   state: "error",
      //   text: `Un problème est survenu.`,
      // });
      // setIsVisible(true);
      toast.error("Un problème est survenu.");
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

function deleteObjById(id: string, array: any[]): any[] {
  const index = array.findIndex((objet: any) => objet.id === id);

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

export const removeFromProject = async (
  userId: string,
  projectId: string,
  setPopup?: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const docRef = doc(database, "projects", projectId);
  const querySnapshot = await getDoc(docRef);

  if (querySnapshot.exists()) {
    const project = querySnapshot.data();
    let members = project.members;

    const docRef = doc(database, "projects", `${projectId}`);

    await updateDoc(docRef, {
      members: deleteObjById(userId, members),
    })
      .then(() => {
        // setPopup({
        //   state: "success",
        //   text: `Membre retiré.`,
        // });
        // setIsVisible(true);

        const q1 = query(
          collection(database, "projects", `${projectId}`, "tasks")
        );

        onSnapshot(q1, async (querySnapshot) => {
          querySnapshot.forEach(async (obj) => {
            let task = obj.data();

            removeFromTask(userId, task.id, projectId, setPopup, setIsVisible);
          });

          sendMailV2(
            userId,
            "Vous avez été retiré d'un projet.",
            `Vous avez été retiré d'un projet. Connectez-vous pour voir de quel projet s'agit-il.`
          );
          toast.success("L'utilisateur a bien été retiré du projet.");
        });
      })
      .catch(() => {
        // setPopup({
        //   state: "error",
        //   text: `Une erreur s'est produite`,
        // });
        // setIsVisible(true);
        toast.error("Une erreur s'est produite");
      });
  }
};

export const removeFromTask = async (
  userId: string,
  taskId: string,
  projectId: string,
  setPopup?: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    // Requête pour récupérer la tâche spécifique
    const q = query(
      collection(database, "projects", projectId, "tasks"),
      where("id", "==", taskId)
    );

    const querySnapshot = await getDocs(q);

    // Pour chaque tâche correspondante (devrait être une seule tâche)
    querySnapshot.forEach(async (obj) => {
      const task = obj.data();
      const members = task.members || [];

      // Référence vers la tâche dans Firestore
      const collectionRef = collection(database, `projects/${projectId}/tasks`);
      const docRef = doc(collectionRef, taskId);

      // Mise à jour de la tâche en retirant le membre spécifique
      await updateDoc(docRef, {
        members: deleteObjById(userId, members),
      });

      // Notification de succès
      toast.success("Le membre a bien été retiré.");
    });
  } catch (err) {
    console.error("Erreur lors du retrait du membre:", err);
    toast.error("Une erreur s'est produite lors du retrait du membre.");
  }
};

export const saveTask = async (
  project: DocumentData,
  name: string,
  description: string,
  location: string,
  status: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  end?: any,
  start?: any
) => {
  setIsLoading(true);

  const taskId = uuid();
  let data = {
    id: taskId,
    name: name,
    description: description,
    location: location,
    members: [],
    teams: [],
    status: status,
    finished: status === "finished" ? new Date() : null,
    projectId: project.id,
    created: new Date(),
    start: Number.isNaN(start.seconds) ? new Date() : start,
    end: Number.isNaN(end.seconds) ? project.end : end,
    duplicate: null,
  };

  const collectionRef = collection(
    database,
    "projects/" + `${project.id}` + "/tasks/"
  );
  const docRef = doc(collectionRef, taskId);

  await setDoc(docRef, data)
    .then(() => {
      setPopup({
        state: "success",
        text: `Tache créée.`,
      });
    })
    .catch(() => {
      setPopup({
        state: "error",
        text: `Un problème est survenu.`,
      });
      setIsVisible(true);
    })
    .finally(() => {
      setIsLoading(false);
      setOpen(false);
    });
};

export const assignUserToTask = async (
  id: string,
  project: DocumentData,
  task: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setSelected && setSelected([]);

    // Créer la requête pour récupérer l'utilisateur par ID
    const q = query(collection(database, "users"), where("id", "==", `${id}`));

    // Récupérer les documents correspondant à la requête
    const querySnapshot = await getDocs(q);

    // Parcourir les résultats
    querySnapshot.forEach(async (docSnapshot) => {
      let user = docSnapshot.data();

      let data = {
        id: user.id,
        finished: false,
        name: user.name,
        completed: false,
        email: user.email,
        added: new Date(),
        picture: user.picture,
        nickname: user.nickname,
      };

      const collectionRef = collection(
        database,
        "projects",
        `${project.id}`,
        "tasks"
      );
      const docRef = doc(collectionRef, task.id);

      console.log(data);
      console.log("Assigning.....");

      // Mettre à jour le document de la tâche avec le nouveau membre
      setDoc(
        docRef,
        {
          members: arrayUnion(data),
        },
        { merge: true }
      ).then(() => {
        sendMailV2(
          data.id,
          "Vous avez été assigné à une tâche.",
          `Félicitations Mr ${data.name}, vous avez été ajouté à la tâche "${task.name}" du projet "${project.name}".`
        );
        toast.success("Le membre a bien été assigné.");
      });

      // Mise à jour de l'interface utilisateur
      // setOpen && setOpen(false);

      // Optionnel : envoyer un email à l'utilisateur assigné à la tâche
      // sendEmailToPerson(id, "added-to-task", {
      //   reason: "added-to-task",
      //   taskName: task.name,
      // });
    });
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout du membre:",
      error
    );
    toast.error("Une erreur s'est produite lors de l'ajout du membre.");
    setSelected && setSelected([]);
    setOpen && setOpen(false);
  }
};

export const changeTaskStatus = (
  project: DocumentData,
  task: DocumentData,
  status: string
) => {
  if (status === "finished") {
    console.error(
      "This function cannot be used to change task status to 'finished'. Please use 'markAsFinished' function."
    );
  } else {
    const collectionRef = collection(database, "projects", project.id, "tasks");

    const docRef = doc(collectionRef, task.id);

    updateDoc(docRef, {
      status: status,
    }).then(() => {
      toast.success("Le status de la tâche a été modifié.");
    });
  }
};

export const markAsFinished = (
  projectId: string,
  taskId: string,
  setPopup?: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const collectionRef = collection(
    database,
    "projects/" + `${projectId}` + "/tasks/"
  );

  const docRef = doc(collectionRef, taskId);

  updateDoc(docRef, {
    status: "finished",
    finished: new Date(),
  })
    .then(() => {
      // setPopup &&
      //   setPopup({
      //     state: "success",
      //     text: "Marqué comme terminé !",
      //   });
      // setIsVisible && setIsVisible(true);
      toast.success("La tâche a été marquée comme terminée.");
    })
    .catch(() => {
      // setPopup &&
      //   setPopup({
      //     state: "error",
      //     text: "Une erreur s'est porduite.",
      //   });
      // setIsVisible && setIsVisible(true);
      toast.error("Un problème est survenu.");
    });
};

export const assignTeamToTask = async (
  teamId: string,
  project: DocumentData,
  task: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    // Créer la requête pour récupérer l'équipe par ID
    const q = query(
      collection(database, "teams"),
      where("id", "==", `${teamId}`)
    );

    // Récupérer les documents correspondant à la requête
    const querySnapshot = await getDocs(q);

    // Parcourir les résultats
    querySnapshot.forEach(async (docSnapshot) => {
      let team = docSnapshot.data();
      const members = team.members;

      const collectionRef = collection(
        database,
        "projects",
        `${project.id}`,
        "tasks"
      );
      const docRef = doc(collectionRef, task.id);

      // Mettre à jour le document de la tâche avec l'équipe
      await updateDoc(docRef, {
        teams: arrayUnion({
          id: team.id,
          name: team.name,
        }),
      });

      // Assignation des membres de l'équipe à la tâche
      for (const member of members) {
        console.log(member);
        if (setSelected && setOpen) {
          await assignUserToTask(
            member.id,
            project,
            task,
            setSelected,
            setOpen
          );
        } else {
          await assignUserToTask(member.id, project, task);
        }
      }

      setSelected && setSelected([]);

      // Notification de succès
      toast.success(
        `L'équipe "${team.name}" a bien été assignée à la tâche "${task.name}".`
      );
    });
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'assignation de l'équipe à la tâche:",
      error
    );
    toast.error(
      "Une erreur s'est produite lors de l'assignation de l'équipe à la tâche."
    );
  }
};

type member = {
  id: string;
  name: string;
  picture: string;
  nickname: string;
  email: string;
  role: string;
};

export const getTaskMembers = (
  taskId: string,
  project: DocumentData,
  setMembers: React.Dispatch<React.SetStateAction<member[] | undefined>>
) => {
  const q = query(
    collection(database, "projects", `${project.id}`, "tasks"),
    where("id", "==", `${taskId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    setMembers([]);
    querySnapshot.forEach((obj) => {
      let task = obj.data();
      let members = task.members;

      for (const member of members) {
        setMembers((old: any) => [...old, member]);
      }
    });
  });
};

export const removeTeamFromProject = async (id: string, projectId: string) => {
  const result = window.confirm(
    `Voulez vraiment retirer les membres de cette équipe de ce projet?`
  );

  if (!result) {
    return;
  }

  try {
    // Récupérer l'équipe avec l'ID correspondant
    const q = query(collection(database, "teams"), where("id", "==", id));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error("Aucune équipe trouvée avec cet ID.");
    }

    // Suppression des membres du projet
    const teamDoc = querySnapshot.docs[0]; // Récupère le premier document correspondant à la requête
    const team = teamDoc.data();
    const members = team.members;

    for (const member of members) {
      await removeFromProject(member.id, projectId);
    }

    // Mise à jour du projet pour retirer l'équipe
    const projectRef = doc(database, "projects", projectId);
    const projectSnap = await getDoc(projectRef);

    if (projectSnap.exists()) {
      const projectData = projectSnap.data();

      if (Array.isArray(projectData.teams)) {
        const updatedTeams = projectData.teams.filter(
          (team: any) => team.id !== id
        );

        // Mise à jour du document du projet avec les équipes mises à jour
        await updateDoc(projectRef, {
          teams: updatedTeams,
        });

        toast.success("L'équipe a bien été retirée du projet.");
      }
    } else {
      throw new Error("Projet introuvable.");
    }
  } catch (error) {
    console.error(
      "Erreur lors de la suppression de l'équipe du projet:",
      error
    );
    toast.error("Une erreur est survenue lors de la suppression de l'équipe.");
  }
};

export const getTeamUsers = (
  id: string,
  setMembers: React.Dispatch<React.SetStateAction<member[] | undefined>>
) => {
  const q = query(collection(database, "teams"), where("id", "==", `${id}`));

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let team = obj.data();
      let members = team.members;

      for (const member of members) {
        setMembers((old: any) => {
          const exists = old.some(
            (existingMember: any) => existingMember.id === member.id
          );

          if (!exists) {
            return [...old, member];
          }

          return old;
        });
      }
    });
  });
};

export const addUserToProject = (
  userId: string,
  project: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "users"),
    where("id", "==", `${userId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let user = obj.data();

      let data = {
        id: user.id,
        finished: false,
        name: user.name,
        email: user.email,
        added: new Date(),
        picture: user.picture ?? "",
        nickname: user.nickname,
        role: "Membre",
      };

      console.log(data);

      const docRef = doc(database, "projects", `${project.id}`);

      updateDoc(docRef, {
        members: arrayUnion(data),
      })
        .then(() => {
          setOpen && setOpen(false);
          setSelected && setSelected([]);
          // sendEmailToPerson(userId, "added-to-project", {
          //   reason: "added-to-project",
          //   projectName: project.name,
          // });
          sendMailV2(
            userId,
            "Vous avez été ajouté à un projet.",
            `Félicitations Mr ${data.name}, vous avez été ajouté au projet "${project.name}".`
          );
        })
        .catch(() => {
          setOpen && setOpen(false);
          setSelected && setSelected([]);
        });
    });
  });
};

export const changeMemberRole = (
  id: string,
  project: DocumentData,
  role: string,
  setPopup: React.Dispatch<React.SetStateAction<popup>>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let members = project.members;

  // eslint-disable-next-line array-callback-return
  members = members.map((obj: any) => {
    if (obj.id === id) {
      return { ...obj, role: role };
    }
    return obj; // Ajoutez cette ligne pour retourner l'objet original si la condition est fausse
  });

  const docRef = doc(database, "projects", `${project.id}`);
  setDoc(docRef, { members: members }, { merge: true })
    .then(() => {
      if (role === "Manager") {
        sendMailV2(
          id,
          "Vous avez été promu au sein d'un projet.",
          `Félicitations, vous avez été promu au rang de Manager du projet "${project.name}".`
        );
      }
      // sendEmailToPerson(id, "promoted-manager-org", {
      //   reason: "promoted-manager-org",
      //   projectName: project.name,
      // });

      // setPopup({
      //   state: "success",
      //   text: "Role modifié !",
      // });
      // setIsVisible(true);
      toast.success("Le rôle a bien été modifié.");
    })
    .catch(() => {
      // setPopup({
      //   state: "error",
      //   text: "Une erreur s'est produite.",
      // });
      // setIsVisible(true);
      toast.error("Une erreur s'est produite.");
    });
};

export const addTeamToProject = (
  teamId: string,
  project: DocumentData,
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const q = query(
    collection(database, "teams"),
    where("id", "==", `${teamId}`)
  );

  onSnapshot(q, async (querySnapshot) => {
    querySnapshot.forEach((obj) => {
      let team = obj.data();
      const members = team.members;

      const docRef = doc(database, "projects", `${project.id}`);

      updateDoc(docRef, {
        teams: arrayUnion({
          id: team.id,
          name: team.name,
        }),
      });

      for (const member of members) {
        updateDoc(docRef, {
          members: arrayUnion(member),
        });
      }

      toast.success("L'équipe a bien été assigné au projet.");
    });
  });
};

// const collectionRef = collection(
//   database,
//   // eslint-disable-next-line no-useless-concat
//   "restaurants/" + `${restaurantID}` + "/waiterTasks/"
// );
// const docRef = doc(collectionRef, uuid());
// const documentID = docRef.id;

// let token = tokenCreator();

// await setDoc(docRef, {
//   id: documentID,
//   served: false,
//   restaurantID: restaurantID,
//   token: token,
//   formulasID: formulasID,
//   clientID: id,
// });
